@import '~bootstrap/scss/bootstrap.scss'

@font-face
  font-family: 'Podpisi'
  src: url('./fonts/Podpisi.otf') format('opentype')

@font-face
  font-family: 'Zagolovki'
  src: url('./fonts/Zagolovki.otf') format('opentype')

.desktop-only
  @include media-breakpoint-down(md)
    display: none
.mobile-only
  @include media-breakpoint-up(lg)
    display: none

.noselect
  -webkit-touch-callout: none /* iOS Safari */
  -webkit-user-select: none /* Safari */
  -khtml-user-select: none /* Konqueror HTML */
  -moz-user-select: none /* Firefox */
  -ms-user-select: none /* Internet Explorer/Edge */
  user-select: none /* Non-prefixed version, currently supported by Chrome and Opera */

.abs-cover
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0

.def-img
  background-repeat: no-repeat
  background-position: center
  background-size: cover

.button
  padding: 0
  cursor: pointer
  outline: none
  border: none
  background-color: rgba(0, 0, 0, 0)

@function rem($px)
  @return calc(#{$px} / 16 * 1rem)

// COLORS
$dark: black
$light: white

*
  font-family: 'Podpisi', Helvetica, sans-serif
  color: $light
  margin: 0
  padding: 0
  box-sizing: border-box
  -webkit-font-smoothing: antialiased

body
  background-color: $dark

.App
  width: 100%
  height: 100%
  font-size: rem(20)
  overflow: visible
  // @extend .d-flex
  // @extend .flex-column

.Header
  &--placeholder
    border: none
    margin-bottom: rem(50)
  &--fixed
    position: fixed
    top: 0
    left: 0
    z-index: 999
    width: 100vw
    background-color: black
  height: rem(70)
  min-height: rem(70)
  border-bottom: 1px solid $light
  // margin-bottom: rem(50)
  @extend .d-flex
  @extend .flex-row
  @extend .align-items-center
  @extend .justify-content-md-around
  @extend .justify-content-between
  &__logo
    height: 36px
    transform: translateY(-7px)
    width: auto
    display: flex-item
    padding-right: 20px
  &__links
    @extend .h-100
    @extend .d-flex
    @extend .flex-grow-1
    @extend .flex-md-row
    @extend .flex-column
    @extend .align-items-md-center
    @extend .justify-content-around
    // @extend .justify-content-md-end
    // @extend .justify-content-start
    // text-transform: uppercase
    .Link
      font-family: 'Zagolovki', Helvetica, sans-serif !important
      font-size: rem(18) !important
      color: $light
      line-height: 55px
      padding: 0 10px
      &:hover,
      &--active
        // background-color: #454545
        // color: $light
        text-decoration: underline
    @include media-breakpoint-down(md)
      background-color: $dark
      padding-right: rem(20)
      position: fixed
      top: rem(70)
      right: 0
      width: 100%
      z-index: 200
      .Link
        margin-top: rem(10)
        margin-bottom: rem(10)
        text-align: right
      &:not(&--opened)
        display: none !important
      &--opened
        height: unset !important
  &__burger
    @extend .mobile-only
    width: rem(42)
    height: rem(42)
    background-image: url(./img/burger.svg)
    @extend .def-img
    margin-right: rem(16)

.Link
  color: $light

.content
  width: 100%
  @extend .flex-grow-1
  overflow-x: hidden
  overflow-y: overlay
  // padding-top: rem(50)

.Layout
  &__project
    margin-bottom: rem(70)
    &__video
      @extend .d-flex
      @extend .flex-md-row
      @extend .flex-column
      @extend .align-items-center
      @extend .justify-content-around
      &__item
        @extend .flex-grow-1
        @extend .mx-3
    &__h1
      font-family: 'Zagolovki', Helvetica, sans-serif
      position: relative
      z-index: 100
      // @include media-breakpoint-up(md)
      //   margin-top: rem(-50)
      text-align: center
      @extend .my-4
    &__description
      position: relative
      z-index: 100
      text-align: center

.video
  width: 100%
  position: relative
  @extend .mt-2
  padding-top: calc(100% / 1920 * 1080)
  overflow: hidden
  &__iframe
    @extend .abs-cover
    &::after
      content: ''
      @extend .abs-cover
      opacity: 0
  &__mobile
    width: 100% !important
    margin-top: -56.25%
    height: auto
  &__thumbnail
    @extend .abs-cover
    &__img
      cursor: pointer
      .Img__img
        cursor: pointer
    &__play-buttonn
      cursor: pointer
      // opacity: 75%
      position: absolute
      top: 50%
      left: 50%
      width: 50px
      height: auto
      transform: translate(-50%, -50%)

.contact_text
  padding-bottom: 70px


.Contacts
  &__name
    width: 100%
    @extend .h1
    text-align: left
    @extend .mb-5
    color: #72FD7D
    @include media-breakpoint-up(lg)
      font-size: 136px
      line-height: 130px
  &__links
    @extend .col-12
    @extend .col-lg-6
    @extend .d-flex
    @extend .justify-content-between
    @extend .align-content-start
    @extend .flex-wrap
    @extend .mb-5
    // margin-top: 10rem
    @include media-breakpoint-down(md)
      margin-top: 0
    &__item
      width: 29px
      height: 29px
      margin-right: 29px
      cursor: pointer
      @extend .d-flex
      @extend .flex-column
      @extend .justify-content-between
      @extend .align-items-center
  &__photo
    @extend .col-12
    @extend .col-lg-6
    @extend .d-flex
    @extend .justify-content-center
    @extend .mb-5
    align-items: flex-start
    img
      width: 90%
      height: auto
      @include media-breakpoint-down(md)
        width: 70%
      @include media-breakpoint-down(sm)
        width: 70%
  &__Lev
    width: 100%
    margin-top: rem(100)
    text-align: center

figcaption
  text-shadow: $dark 0 0 5px !important

figure
  img
    cursor: zoom-out !important



.Img
  &--crop
    @extend .abs-cover
    overflow: hidden
  &--nocrop
    @extend .d-flex
    // .Img__img
    //   width: 100%
  &__img
    // cursor: zoom-in
    width: 100%
    // &--portrait,
    // &--landscape
    //   position: absolute
    //   top: 50%
    //   left: 50%
    //   transform: translate(-50%, -50%)
    // &--portrait
    //   width: 100%
    //   height: auto
    // &--landscape
    //   width: auto
    //   height: 100%
  &--default
    position: relative
    width: 100%
  &--fullscreen
    position: fixed
    z-index: 1000
    top: 0
    left: 0
    content: ''
    width: 100vw
    height: 100vh
    background-color: rgba(0, 0, 0, .7)
    cursor: zoom-out
    .Img__img
      cursor: zoom-out
      


.h1
  font-family: 'Zagolovki', Helvetica, sans-serif

.mb-70
  margin-bottom: 70px